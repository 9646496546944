import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "rc-slider";
import Cropper from "react-easy-crop";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "rc-slider/assets/index.css";
import { ReactComponent as VerifiedWhiteFilled } from "../../assets/svgs/VerifiedWhiteFilled.svg";
import { ReactComponent as SuccessFilledWhite } from "../../assets/svgs/SuccessFilledWhite.svg";
import Text from "../../Component/Common/Text";
import Tags from "../../Component/Common/Tags/Tags";
import "./Settings.css";
import SideBar from "../../Component/SideBar/SideBar";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/FooterNew/FooterNew";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import AccountDetails from "../../Component/AccountDetails/AccountDetails";
import PaymentDetails from "../../Component/PaymentDetails/PaymentDetails";
import Banner from "../../Component/Common/Banner/Banner";
import InvoiceHistory from "../Settings/InvoiceHistory";
import CentralCustomModal from "../../Component/Common/CentralModals/CentralCustomModal";
import CentralModalSuccess from "../../Component/Common/CentralModals/CentralModalSuccess";
import CardRadioButton from "../../Component/Common/Controls/CardRadioButton";
import GetCroppedImage from "../../Component/Common/GetCroppedImage";
import {
  SHOW_ACTIVITY_INDICATOR,
  SHOW_ACTIVITY_INDICATOR_MAILCHIMP,
  USER_DATA,
  BANNER_CONTENT,
} from "../../actions";
import {
  StripeAccountConnect,
  getUserDetails,
  getStripeAccountStatus,
  UpdateUserProfile,
  getUrlKycVerify,
  addCard,
  getUsersCards,
  removeCard,
  setDefaultCard,
  payNow,
  getInvoiceHistory,
  GetUserUnpaidAmount,
  getUserPaymentThreshold,
  addUpdateUserPaymentThreshold,
  connectMailChimpAccount,
  getMailChimpLoginURL,
  getMailChimpAccountDetails,
  DisconnectMailChimpAccount,
  removePaymentMethod,
} from "../../services/api";
import { get, set } from "lodash";
// const stripe = new Stripe(
//   "sk_test_51OhsIwArT4t9KNCkQd7G1kl1Mkfh5BgBw7azCWSD7LZRb4SNkafOzPHNr8NtRWVegpjoCLnWjxN1aTaCHC6AiL9e00g5ft7SdV"
// );

// const stripe = new Stripe(
//   "sk_test_tR3PYbcVNZZ796tH88S4VQ2u"
// );

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Settings() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const userInfo = useSelector((state) => state.userData?.userData);
  const countryList = useSelector((state) => state.countryData?.countryData);
  const [showBanner, setShowBanner] = useState(false);
  const [showInvoiceHistory, setShowInvoiceHistory] = useState(false);
  const [accountData, setAccoutData] = useState();
  const [isCropModalVisible, setIsCropModalVisible] = useState(false);
  const [selectedSegmentIndexHelp, setSelectedSegmentIndexHelp] = useState(0);
  const [isCentralModalVisible, setIsCentralModalVisible] = useState(false);
  const [thresholdValue, setThresholdValue] = useState([
    {
      id: 2,
      userId: null,
      currencyId: 3,
      currency: {
        id: 3,
        code: "GBP",
        name: "British Pounds",
        symbol: "£",
        ticketGuardFee: 1.99,
        platformFee: 0.39,
        platformFeePct: 0.01,
        minimumThreshold: 50.0,
        maximumThreshold: 500.0,
      },
      amount: 500.0,
      dateCreated: "2024-05-20T10:28:32.597",
      dateUpdated: "2024-05-21T08:23:06.063",
    },
  ]);
  const [accountMode, setAccountMode] = useState("view");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessMobile, setBusinessMobile] = useState("");
  const [businessMobileCode, setBusinessMobileCode] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [brandImage, setBrandImage] = useState("");
  const [brandImageBase64, setBrandImageBase64] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessProfileImageError, setBusinessProfileImageError] =
    useState("");
  const [isConfirmAccountModalVisible, setIsConfirmAccountModalVisible] =
    useState(false);
  const [isStripeDetailsSubmitted, setIsStripeDetailsSubmitted] =
    useState("no");
  const [cardList, setCardList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState("");
  const [selectedPaymnetCardId, setSelectedPaymnetCardId] = useState("");
  const [balanceObject, setBalanceObject] = useState("");
  const [taxValue, setTaxValue] = useState(undefined);
  const [selectedPayNowIndex, setSelectedPayNowIndex] = useState(null);
  const [selectedThresholdIndex, setSelectedThresholdIndex] = useState(0);
  const [selectedThresholdAmount, setSelectedThresholdAmount] = useState(50);
  const [selectedThresholdMinValue, setSelectedThresholdMinValue] =
    useState(50);
  const [selectedThresholdMaxValue, setSelectedThresholdMaxValue] =
    useState(50);

  const [mailchimpUserInfo, setMailchimpUserInfo] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);


  const SettingsOptions = [
    { name: "Account", id: 1 },
    { name: "Payment set-up", id: 2 },
    { name: "Billing", id: 3 },
    { name: "Connections", id: 4 },
  ];

  const [socialList, setSocialList] = useState([
      {
        name: "Select an option",
        url: "",
        isSaved: false,
        assistiveText: "",
        descriptiveText: "",
      },
    ]);

  const query = useQuery();
  const mailChimpCode = query.get("code");
  const location = useLocation();

  const [toCropImage, setToCropImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await GetCroppedImage(
        toCropImage,
        croppedAreaPixels
      );
      console.log("donee", { croppedImage });
      setBrandImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (
      localStorage.getItem("shouldCallRefreshUrl") === "true" ||
      localStorage.getItem("kycBeingVerified") === "true"
    ) {
      setSelectedSegmentIndexHelp(
        localStorage.getItem("shouldCallRefreshUrl") === "true" ? 1 : 0
      );
      userData();
    } else {
      userInfo?.stripeAccountId &&
        userInfo?.stripeAccountId !== null &&
        getStripeAccountStatusRequest(userInfo?.stripeAccountId);
    }
    getUserUnpaidAmountRequest();
    getUsersCardsRequest();
    getUserPaymentThresholdRequest();

    const socials = Object.entries(userInfo?.businesses[userInfo?.businesses?.length - 1]?.socialMedia)
  .filter(([key, value]) => key !== "id" && key !== "webUrl") // Exclude "id" and "webUrl"
  .map(([key, value]) => ({
    name: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
    url: value,
    isSaved: true,
    assistiveText: "",
    descriptiveText: ""
  }));

  setSocialList(socials);

  }, []);

  useEffect(() => {
    if (mailChimpCode) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR_MAILCHIMP, payload: true });
      setSelectedSegmentIndexHelp(3);
      const newQuery = new URLSearchParams(location.search);
      newQuery.delete("code");
      const newUrl = `${window.location.pathname}?${newQuery.toString()}`;
      window.history.replaceState(null, "", newUrl);
      setTimeout(() => {
        connectMailChimpAccountRequest(mailChimpCode);
      }, 1000);
    } else {
      setTimeout(() => {
        getMailChimpAccountDetailsRequest();
      }, 2000);
    }
  }, [mailChimpCode]);

  useEffect(() => {
    setName(userInfo?.firstName + " " + userInfo?.lastName);
    setEmail(userInfo?.emailAddress);
    setBusinessName(
      userInfo?.businesses[userInfo?.businesses?.length - 1]?.name
    );
    setBusinessEmail(
      userInfo?.businesses[userInfo?.businesses?.length - 1]?.emailAddress
    );

    const phoneNumber =
      userInfo?.businesses[userInfo?.businesses?.length - 1]?.phone;
    const [countryCode, number] =
      phoneNumber?.trim().length > 0
        ? phoneNumber?.split(phoneNumber.includes("-") ? "-" : " ")
        : ["", ""];
    const formattedCountryCode = countryCode.startsWith("+")
      ? countryCode
      : `+${countryCode ? countryCode : "44"}`;

    setBusinessMobile(number);
    setBusinessMobileCode(formattedCountryCode);
    setBusinessWebsite(
      userInfo?.businesses[userInfo?.businesses?.length - 1]?.webSiteURL
    );
    setBrandImage(
      userInfo?.businesses[userInfo?.businesses?.length - 1]?.imageUri
        ? process.env.REACT_APP_API_URL +
            userInfo?.businesses[userInfo?.businesses?.length - 1]?.imageUri
        : ""
    );
  }, [userInfo]);

  useEffect(() => {
    async function fetchData() {
      try {
        const imageBlob = await fetchImage(brandImage);
        const base64Data = await blobToBase64(imageBlob);
        setBrandImageBase64(base64Data);
      } catch (error) {
        console.error("Error converting image:", error);
      }
    }

    brandImage !== "" && fetchData();
  }, [brandImage]);

  const userData = async () => {
    const result = await getUserDetails();
    if (result.status) {
      dispatch({ type: USER_DATA, payload: result.response });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      // if (
      //   result.response?.kycStatus === "InProgress" &&
      //   localStorage.getItem("kycBeingVerified") === "true"
      // ) {
      //   dispatch({
      //     type: BANNER_CONTENT,
      //     payload: {
      //       text: "Your account is currently being reviewed. This may take up to x days to complete",
      //       headingText: "Verification pending...",
      //       showBanner: true,
      //       type: "Success",
      //       withClose: true,
      //       withIcon: true,
      //       position: "top",
      //       width: 391,
      //     },
      //   });
      // }
      result?.response?.stripeAccountId &&
        result?.response?.stripeAccountId !== null &&
        getStripeAccountStatusRequest(result.response.stripeAccountId);
      localStorage.setItem("kycBeingVerified", false);
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getStripeAccountStatusRequest = async (stripeAccountId) => {
    const result = await getStripeAccountStatus({
      stripeAccountId: stripeAccountId,
    });
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setIsStripeDetailsSubmitted(result.response.isDetailsSubmitted);
      if (
        localStorage.getItem("shouldCallRefreshUrl") === "true" &&
        result.response.isDetailsSubmitted === true
      ) {
        localStorage.setItem("shouldCallRefreshUrl", false);
        setShowBanner(true);
      } else {
        localStorage.setItem("shouldCallRefreshUrl", false);
      }
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const updateUserProfileRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const foundCountry = countryList.find(
      (country) =>
        country.dialingCode === parseInt(businessMobileCode.substring(1))
    );

    const spaceIndex = name.indexOf(" ");
    const firstName = name.substring(0, spaceIndex);
    const lastName = name.substring(spaceIndex + 1);

    let params = {
      firstName: firstName,
      lastName: lastName,
      emailAddress: email,
      businessName: businessName,
      businessEmailAddress: businessEmail,
      businessProfileImage: {
        image: brandImageBase64,
        contentType: "png",
      },
      businessMobileNumber: {
        countryId: foundCountry?.id,
        countryCode: String(foundCountry?.dialingCode),
        number: businessMobile,
      },
      websiteURL: businessWebsite,
      twitter: socialList.find((item) => item.name === "X")?.url || "",
          instagram:
            socialList.find((item) => item.name === "Instagram")?.url || "",
          linkedIn:
            socialList.find((item) => item.name === "LinkedIn")?.url || "",
          facebook:
            socialList.find((item) => item.name === "Facebook")?.url || "",
          tikTok: socialList.find((item) => item.name === "TikTok")?.url || "",
          youTube:
            socialList.find((item) => item.name === "YouTube")?.url || "",
    };

    console.log('params:::', params)

    const result = await UpdateUserProfile(params);
    if (result.status) {
      setAccountMode("view");
      userData();
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: result?.response?.errorMessage,
          headingText: "",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
  };

  const addCardRequest = async (sourceToken) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const params = { sourceToken: String(sourceToken) };
    const result = await addCard(params);
    if (result.status) {
      getUsersCardsRequest();
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getUsersCardsRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getUsersCards();
    if (result.status) {
      setCardList(result.response?.cards);
      setSelectedCardId(result.response?.cards[0]?.id);
      getInvoiceHistoryRequest();
    } else {
      // dispatch({
      //   type: BANNER_CONTENT,
      //   payload: {
      //     text: "There has been an error while fetching your payment card. Please check your details and try again.",
      //     headingText: "Payment card not found",
      //     showBanner: true,
      //     type: "Error",
      //     withClose: true,
      //     withIcon: true,
      //     position: "top",
      //     width: 391,
      //   },
      // });
      setCardList([]);
      setSelectedCardId('');
      getInvoiceHistoryRequest();
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const removeCardRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const params = { stripeCardId: selectedPaymnetCardId };
    const result = await removeCard(params);
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      getUsersCardsRequest();
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const removePaymentMethodRequest = async (cardId) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await removePaymentMethod({});
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setIsStripeDetailsSubmitted(false);
      userData();
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "There has been an error removing your payment method. Please check your details and try again.",
          headingText: "Remove payment method not successful",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const setDefaultCardRequest = async (cardId) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const params = { stripeCardId: cardId };
    const result = await setDefaultCard(params);
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      getUsersCardsRequest();
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };
  const getUserUnpaidAmountRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await GetUserUnpaidAmount();
    if (result.status) {
      setBalanceObject(result?.response?.currencyWiseFees || []);
      setTaxValue(
        result?.response?.isVatRequired
          ? result?.response?.vatPercentage || 0
          : undefined
      );
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    } else {
      setBalanceObject([]);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getInvoiceHistoryRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getInvoiceHistory();
    if (result.status) {
      setInvoiceList(result.response?.invoiceList);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const payNowRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const params = {
      stripeCardId: selectedCardId,
      currencyCode: balanceObject[selectedPayNowIndex]?.code,
      amount: balanceObject[selectedPayNowIndex]?.unpaidFees,
      eventWiseFees: balanceObject[selectedPayNowIndex]?.eventWiseFees,
    };
    const result = await payNow(params);
    setIsCentralModalVisible(false);
    if (result.status) {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "Balance payment successful",
          headingText: `You have successfully paid your balance of ${balanceObject[selectedPayNowIndex]?.symbol}${balanceObject[selectedPayNowIndex]?.unpaidFees}.`,
          showBanner: true,
          type: "Success",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      getUserUnpaidAmountRequest();
      getInvoiceHistoryRequest();
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "There has been an error processing your payment. Please check your details and try again.",
          headingText: "Balance payment not successful",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getUserPaymentThresholdRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getUserPaymentThreshold();
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setThresholdValue(result.response?.userPaymentThresholds);
    } else {
      setThresholdValue([
        {
          id: 2,
          userId: null,
          currencyId: 3,
          currency: {
            id: 3,
            code: "GBP",
            name: "British Pounds",
            symbol: "£",
            ticketGuardFee: 1.99,
            platformFee: 0.39,
            platformFeePct: 0.01,
            minimumThreshold: 50.0,
            maximumThreshold: 500.0,
          },
          amount: 500.0,
          dateCreated: "2024-05-20T10:28:32.597",
          dateUpdated: "2024-05-21T08:23:06.063",
        },
      ]);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const addUpdateUserPaymentThresholdRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const params = {
      amount: selectedThresholdAmount,
      currencyId: thresholdValue[selectedThresholdIndex]?.currencyId,
    };
    const result = await addUpdateUserPaymentThreshold(params);
    setIsCentralModalVisible(false);
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      getUserPaymentThresholdRequest();
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const segmentBtnPressedHelp = (index) => {
    setShowInvoiceHistory(false);
    setSelectedSegmentIndexHelp(index);
  };

  const removePlusFromCountryCode = (phoneNumber) => {
    if (phoneNumber.startsWith("+")) {
      return phoneNumber.replace("+", "");
    }
    return phoneNumber;
  };

  const stripeConnect = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });

    const result = await StripeAccountConnect({
      AccountType: "express",
      EmailAddress: userInfo.emailAddress,
      // CountryCode: "US",
      returnURL: `${process.env.REACT_APP_WEB_URL}/Settings`,
    });
    if (result.status) {
      localStorage.setItem("shouldCallRefreshUrl", true);
      window.open(result.response.url, "_self");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const verifyAccountRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getUrlKycVerify({
      emailAddress: userInfo?.emailAddress,
      returnURL: `${process.env.REACT_APP_WEB_URL}/Settings`,
    });
    if (result.status) {
      localStorage.setItem("kycBeingVerified", true);
      window.open(result.response, "_self");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getMailChimpLoginURLRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getMailChimpLoginURL();
    if (result.status) {
      console.log("MailChimp URL", result.response.url);
      // localStorage.setItem("shouldCallRefreshUrl", true);
      window.open(result.response.url, "_self");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const connectMailChimpAccountRequest = async (mailChimpCode) => {
    const result = await connectMailChimpAccount({ code: mailChimpCode });
    console.log("MailChimp connect result", result, mailChimpCode);
    if (result.status) {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "Successfully connected to your Mailchimp account.",
          headingText: "Mailchimp connected",
          showBanner: true,
          type: "Success",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      getMailChimpAccountDetailsRequest();
      setTimeout(() => {
        dispatch({ type: SHOW_ACTIVITY_INDICATOR_MAILCHIMP, payload: false });
      }, 2000);
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "There has been an error connceting your mailchimp account. Please check your details and try again.",
          headingText: "Mailchimp not connected",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR_MAILCHIMP, payload: false });
    }
    
  };

  const getMailChimpAccountDetailsRequest = async () => {
    const result = await getMailChimpAccountDetails();
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setMailchimpUserInfo(result.response.mailChimpUserAccount);
    } else {
      setMailchimpUserInfo(false);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const disconnectMailChimpAccountRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await DisconnectMailChimpAccount();
    if (result.status) {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "Successfully disconnected from your Mailchimp account.",
          headingText: "Mailchimp disconnected",
          showBanner: true,
          type: "Success",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      getMailChimpAccountDetailsRequest();
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "There has been an error disconnecting your mailchimp account. Please check your details and try again.",
          headingText: "Mailchimp not disconnected",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

  async function fetchImage(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  const renderPayNow = () => {
    return (
      <div
        className="central-modal-contentPopup-bottomList"
        style={{
          width: "100%",
          zIndex: 1,
          height: "100%",
          overflowY: "auto",
        }}
      >
        <div>
          <div
            style={{ marginTop: 32, marginBottom: 48 }}
            className="generic-common-divider"
          />
          <Text variant={"m500"}>Amount to pay</Text>
          <div
            style={{ marginTop: 36, marginBottom: 48 }}
            className="generic-common-row-div"
          >
            <div
              style={{ backgroundColor: "var(--surfaceDisabled)" }}
              className="amount-pay-container"
            >
              <Text
                color={"var(--textDisabled)"}
                textclass="Heading-div"
                variant={"r200"}
              >
                Total amount due
              </Text>
              <Text
                color={"var(--textDisabled)"}
                textclass="Heading-div"
                variant={"m400"}
              >
                {`${balanceObject[selectedPayNowIndex]?.symbol}${
                  taxValue
                    ? parseFloat(
                        balanceObject[selectedPayNowIndex]?.unpaidFees +
                          taxValue
                      ).toFixed(2)
                    : balanceObject[selectedPayNowIndex]?.unpaidFees.toFixed(2)
                }`}
              </Text>
            </div>

            <div
              style={{ marginLeft: 24, backgroundColor: "var(--white)" }}
              className="amount-pay-container"
            >
              <Text textclass="Heading-div" variant={"r200"}>
                Amount
              </Text>
              <Text textclass="Heading-div" variant={"m400"}>
                {`${balanceObject[selectedPayNowIndex]?.symbol}${
                  taxValue
                    ? parseFloat(
                        balanceObject[selectedPayNowIndex]?.unpaidFees +
                          taxValue
                      ).toFixed(2)
                    : balanceObject[selectedPayNowIndex]?.unpaidFees.toFixed(2)
                }`}
              </Text>
            </div>
          </div>

          <div style={{ marginBottom: 24 }}>
            <Text variant={"m500"}>Select payment method</Text>
          </div>

          <CardRadioButton
            twoColumn={true}
            marginRight={24}
            styles={{ marginBottom: 20 }}
            data={cardList}
            selectIndex={cardList.findIndex((card) => card.isDefault)}
            onSelect={(item, index) => {
              setSelectedCardId(item?.id);
            }}
          />

          <div className="generic-common-row-centerd-div">
            <ButtonPrimary
              width={328}
              label={"Pay now"}
              onPress={() => payNowRequest()}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCropEventBanner = () => {
    return (
      <div
        className="crop-event-banner-container crop-banner-for-square"
        style={{
          margin: "17px",
          width: "700px",
          height: "393.75",
          alignSelf: "center",
        }}
      >
        <Cropper
          // image="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
          image={toCropImage}
          crop={crop}
          zoom={zoom}
          minZoom={1}
          maxZoom={10}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          ref={imageRef}
        />

        <input
          style={{ width: "100%", marginTop: 24 }}
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value);
          }}
          className="zoom-range"
        />

        <div
          className="central-bottom-button-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 24,
            marginBottom: 0,
            justifyContent: "flex-end",
          }}
        >
          <div className="generic-common-row-div">
            <ButtonPrimary
              label={"Use original"}
              onPress={() => {
                setIsCropModalVisible(false);
                setBrandImage(toCropImage);
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              label={"Save"}
              onPress={() => {
                showCroppedImage();
                setIsCropModalVisible(false);
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              type={"error"}
              isSecondary={true}
              label={"Cancel"}
              onPress={() => setIsCropModalVisible(false)}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }
    try {
      const token = await stripe.createToken(elements.getElement(CardElement));
      console.log("Token created:", token);
      setIsCentralModalVisible(false);
      if (token.error) {
        dispatch({
          type: BANNER_CONTENT,
          payload: {
            text: token?.error?.message,
            headingText: "",
            showBanner: true,
            type: "Error",
            withClose: true,
            withIcon: true,
            position: "top",
            width: 391,
          },
        });
      } else {
        addCardRequest(token?.token?.id);
      }
    } catch (error) {
      console.error("Error creating token:", error);
    }
  };

  const handleCardChange = (event) => {
    setIsCardComplete(event.complete); // Update state based on whether card details are complete
  };

  const renderAddPaymentMethod = () => {
    return (
      <form onSubmit={handleSubmit}>
        <CardElement onChange={handleCardChange} 
          id="card-element"
          options={{
            disableLink: true,
            hidePostalCode: true,
            iconStyle: "solid",
            style: {
              base: {
                iconColor: "grey",
                color: "var(--midnightBlue)",
                fontWeight: 300,
                fontFamily:
                  "'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif",
                fontSize: "21px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                  color: "#fce883",
                },
                "::placeholder": {
                  color: "lightgrey",
                },
              },
              invalid: {
                iconColor: "red",
                color: "red",
              },
              border: "2px solid red", // Add border style here
              borderRadius: "4px",
              padding: "10px 12px",
            },
          }}
        />
        <div
          style={{ marginTop: 32 }}
          className="generic-common-row-centerd-div"
        >
          <button style={{backgroundColor: isCardComplete ? "var(--midBlue)" : "rgb(235, 235, 235)"}} className="add-payment-button" disabled={!stripe || !isCardComplete}>
            Submit
          </button>
        </div>
      </form>
    );
  };

  const renderThreshold = () => {
    return (
      <div
        className="central-modal-contentPopup-bottomList edit-threshold-popup-innerContent"
        style={{
          width: "100%",
          zIndex: 1115,
          height: "90%",
          overflowY: "auto",
          padding: "40px 0px",
        }}
      >
        <Text variant={"r300"}>
          Your payment threshold is the total amount of fees we allow you to
          accumulate before requiring payment. Once the total is reached, this
          payment will be taken.
        </Text>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="generic-common-column-centered-div">
            <Text marginTop={40} variant={"m300"}>
              SELECTED THRESHOLD
            </Text>
            <Text color={"var(--midBlue)"} marginTop={1} variant={"b700"}>
              {`${thresholdValue[selectedThresholdIndex]?.currency?.symbol}${selectedThresholdAmount}`}
            </Text>
            <div
              className="generic-common-column-div"
              style={{ marginTop: 24, width: 576 }}
            >
              <Slider
                onChangeComplete={(value) => {
                  setSelectedThresholdAmount(value);
                }}
                onChange={(value) => {
                  setSelectedThresholdAmount(value);
                }}
                railStyle={{ backgroundColor: "#3C3C432E", height: 5 }}
                trackStyle={{ backgroundColor: "var(--midBlue)", height: 5 }}
                handleStyle={{
                  height: 28,
                  width: 28,
                  marginLeft: 0,
                  marginTop: -12,
                  backgroundColor: "var(--white)",
                  border: "none !important",
                  opacity: 1,
                  boxShadow: "1px 1px 4px 4px rgba(0, 0, 0, 0.07)",
                }}
                handleStyleActive={{
                  border: "none !important",
                }}
                dots={false}
                dotStyle={{ width: 0, height: 0 }}
                activeDotStyle={{ width: 0, height: 0 }}
                value={selectedThresholdAmount}
                step={0}
                min={selectedThresholdMinValue}
                max={selectedThresholdMaxValue}
                marks={{
                  [selectedThresholdMinValue]: {
                    style: {
                      color: "var(--midnightBlue)",
                      fontSize: "20px",
                      marginLeft: 24,
                    },
                    label: (
                      <strong>{`${thresholdValue[selectedThresholdIndex]?.currency?.symbol}${selectedThresholdMinValue}`}</strong>
                    ),
                  },

                  [selectedThresholdMaxValue]: {
                    style: {
                      color: "var(--midnightBlue)",
                      fontSize: "20px",
                      marginLeft: "-30px",
                    },
                    label: (
                      <strong>{`${thresholdValue[selectedThresholdIndex]?.currency?.symbol}${selectedThresholdMaxValue}`}</strong>
                    ),
                  },
                }}
              />
            </div>
          </div>

          <div
            style={{ marginTop: 80 }}
            className="generic-common-row-centerd-div"
          >
            <ButtonPrimary
              style={{ marginRight: 24 }}
              isSecondary={true}
              label={"Cancel"}
              onPress={() => setIsCentralModalVisible(false)}
            />
            <ButtonPrimary
              label={"Confirm"}
              onPress={() => addUpdateUserPaymentThresholdRequest()}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDisconnectMailChimp = () => {
    return (
      <div
        style={{
          paddingTop: 40,
          paddingBottom: 40,
        }}
      >
        <Text variant={"r300"}>
          {
            "Are you sure you want to disconnect your Mailchimp account? You won’t be able to access any linked communication features within Twotixx Box Office."
          }
        </Text>

        <div
          className="central-bottom-button-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            marginBottom: 0,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Yes, confirm"}
            onPress={() => {
              disconnectMailChimpAccountRequest();
              setIsCentralModalVisible(false);
            }}
          />
          <ButtonPrimary
            label={"No, cancel"}
            onPress={() => setIsCentralModalVisible(false)}
            style={{ marginLeft: 32 }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper-home">
      <CentralCustomModal
        title={"Crop image"}
        show={isCropModalVisible}
        onClose={() => setIsCropModalVisible(false)}
        showDividerTop={false}
        renderContent={() => renderCropEventBanner()}
        isDynamicSize={false}
      />

      <CentralModalSuccess
        type={"perosnalDetails"}
        title={
          isConfirmAccountModalVisible === "profileUpdate"
            ? "Confirm personal details" : isConfirmAccountModalVisible === "removePaymentCard" ? "Remove payment card"
            : "Remove payment method"
        }
        description={
          isConfirmAccountModalVisible === "profileUpdate"
            ? `Your personal details have been edited. Once confirmed, you must re-verify your details in the account section. Would you like to continue?`
            : isConfirmAccountModalVisible === "removePaymentCard" ? `Are you sure you want to remove this payment card?`
            : `Are you sure you want to remove this payment method? Ticket sales for live events will be paused until a new payment gateway is added.`
        }
        shouldShowButtons={true}
        button1Title={
          isConfirmAccountModalVisible === "profileUpdate"
            ? "No, cancel"
            : "Yes, confirm"
        }
        button2Title={
          isConfirmAccountModalVisible === "profileUpdate"
            ? "Yes, confirm"
            : "No, cancel"
        }
        onButton1Clicked={() => {
          setIsConfirmAccountModalVisible(false);
          isConfirmAccountModalVisible === "removePaymentMethod" &&
            removePaymentMethodRequest();
          isConfirmAccountModalVisible === "removePaymentCard" && removeCardRequest();
        }}
        onButton2Clicked={() => {
          setIsConfirmAccountModalVisible(false);
          if (isConfirmAccountModalVisible === "profileUpdate") {
            setNameError(name?.trim() === "" ? "Name is required" : "");
            setEmailError(email?.trim() === "" ? "Email is required" : "");
            setBusinessNameError(
              businessName?.trim() === "" ? "Business name is required" : ""
            );
            setBusinessProfileImageError(
              brandImage === "" ? "Business profile image is required" : ""
            );

            if (
              name?.trim() !== "" &&
              email?.trim() !== "" &&
              businessName?.trim() !== "" &&
              brandImage !== ""
            ) {
              updateUserProfileRequest();
            }
          }
          //
        }}
        show={
          isConfirmAccountModalVisible === "profileUpdate" ||
          isConfirmAccountModalVisible === "removePaymentMethod" ||
          isConfirmAccountModalVisible === "removePaymentCard"
        }
        onClose={() => setIsConfirmAccountModalVisible(false)}
      />
      <CentralCustomModal
        type={
          isCentralModalVisible === "addPaymentMethod"
            ? "addPaymentMethod"
            : isCentralModalVisible === "disconnectMailChimp"
            ? "disconnectMailChimp"
            : ""
        }
        showDividerTop={
          isCentralModalVisible === "addPaymentMethod" ||
          isCentralModalVisible === "disconnectMailChimp"
            ? true
            : false
        }
        isDynamicSize={
          isCentralModalVisible === "payNow" ||
          isCentralModalVisible === "addPaymentMethod"
            ? false
            : true
        }
        title={
          isCentralModalVisible === "payNow"
            ? "Pay now"
            : isCentralModalVisible === "addPaymentMethod"
            ? "Add payment method"
            : isCentralModalVisible === "disconnectMailChimp"
            ? "Disconnect Mailchimp account"
            : "Edit payment threshold"
        }
        show={
          isCentralModalVisible === "payNow" ||
          isCentralModalVisible === "threshold" ||
          isCentralModalVisible === "addPaymentMethod" ||
          isCentralModalVisible === "disconnectMailChimp"
        }
        onClose={() => setIsCentralModalVisible(false)}
        renderContent={() =>
          isCentralModalVisible === "payNow"
            ? renderPayNow()
            : isCentralModalVisible === "addPaymentMethod"
            ? renderAddPaymentMethod()
            : isCentralModalVisible === "disconnectMailChimp"
            ? renderDisconnectMailChimp()
            : renderThreshold()
        }
      />

      <Banner
        style={{ width: "100%", left: "366px" }}
        text={"Successfully connected to your [Payment method] account."}
        headingText={"Payment method connected"}
        showBanner={showBanner}
        type={"Success"}
        withIcon={true}
        withClose={true}
        onClose={() => setShowBanner(false)}
        position={"top"}
      />
      <div className="main-body-content">
        <div id="sidebar" className="home-header-sideNav">
          <SideBar
            selectOptionIndex={2}
            onChangeOption={(index) =>
              navigate("/Home", {
                state: { selectedIndex: index },
              })
            }
            createEventClicked={() => navigate("/CreateEvent")}
            onGoToEventClicked={(eventId) =>
              navigate(`/EventDetails/${eventId}`, {
                state: {
                  eventId: eventId,
                },
              })
            }
          />
        </div>
        <div className="rht-body-inner-content">
          <div id="main-header" className="mainTop-header-bar">
            <Header hideCreateEventButton={true} headingText={"Settings"} />
          </div>
          <div className="home-content-div setting-custom-container">
            {showInvoiceHistory ? (
              <InvoiceHistory
                taxValue={taxValue}
                data={invoiceList}
                segmentBtnPressed={(index) => segmentBtnPressedHelp(index)}
              />
            ) : (
              <div
                className="home-content-div-box-page-cont setting-custom-inner"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 64,
                  paddingLeft: 24,
                }}
              >
                <div className="PageTopTabCont">
                  <div className="home-scroll-container PageTopTabScroll">
                    {SettingsOptions.map((items, ind) => {
                      return (
                        <div
                          className="TopTab"
                          key={ind}
                          style={{ marginRight: 12 }}
                        >
                          <Tags
                            paddingVertical={8}
                            label={items.name}
                            selected={
                              ind === selectedSegmentIndexHelp ? true : false
                            }
                            onChangeSelection={() => segmentBtnPressedHelp(ind)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                {selectedSegmentIndexHelp === 0 && (
                  <div>
                    {userInfo?.kycStatus !== "Verified" && (
                      <div className="account-not-verify-block">
                        <div className="account-not-verify-container">
                          <HoverBGButton
                            icon={
                              <VerifiedWhiteFilled height={32} width={32} />
                            }
                            backgroundHover={"var(--twotix-light-blue)"}
                            size={64}
                            onPress={() => {}}
                          />
                          <Text
                            marginTop={24}
                            textclass="Heading-div"
                            variant={"m500"}
                          >
                            {userInfo?.kycStatus === "InProgress"
                              ? "Complete setup"
                              : "Account not verified"}
                          </Text>
                          <Text
                            marginTop={24}
                            textclass="Heading-div"
                            variant={"r400"}
                          >
                            {/* {userInfo?.kycStatus === "InProgress"
                              ? "Your account is currently being reviewed. It may take up to X days for this process to be completed."
                              : "Your seller account needs verifying. Verify now using your passport or driving licence."} */}
                            {
                              "Your seller account needs verifying. Verify now using your passport or driving licence."
                            }
                          </Text>

                          <ButtonPrimary
                            style={{ marginTop: 40 }}
                            width={328}
                            label={"Verify now"}
                            onPress={() => verifyAccountRequest()}
                          />
                        </div>
                      </div>
                    )}
                    <AccountDetails
                      mode={accountMode}
                      name={name}
                      email={email}
                      socialList={socialList}
                      businessName={businessName}
                      businessEmail={businessEmail}
                      businessMobile={businessMobile}
                      businessMobileCode={businessMobileCode}
                      businessWebsite={businessWebsite}
                      nameError={nameError}
                      emailError={emailError}
                      businessNameError={businessNameError}
                      businessProfileImageError={businessProfileImageError}
                      setSocialList={(value) => setSocialList(value)}
                      onNameChange={(value) => {
                        setName(value);
                        setNameError("");
                      }}
                      onEmailChange={(value) => {
                        setEmail(value);
                        setEmailError("");
                      }}
                      onBusinessNameChange={(value) => {
                        setBusinessName(value);
                        setBusinessNameError("");
                      }}
                      onBusinessEmailChange={(value) => setBusinessEmail(value)}
                      onBusinessMobileChange={(value) =>
                        setBusinessMobile(value)
                      }
                      onBusinessWebsiteChange={(value) =>
                        setBusinessWebsite(value)
                      }
                      onEditDetailsBtnClicked={(value) => {
                        setAccountMode(value === true ? "edit" : "view");
                        if (value === false) {
                          setName(
                            userInfo?.firstName + " " + userInfo?.lastName
                          );
                          setEmail(userInfo?.emailAddress);
                          setBusinessName(
                            userInfo?.businesses[
                              userInfo?.businesses?.length - 1
                            ]?.name
                          );
                          setBusinessEmail(
                            userInfo?.businesses[
                              userInfo?.businesses?.length - 1
                            ]?.emailAddress
                          );

                          const phoneNumber =
                            userInfo?.businesses[
                              userInfo?.businesses?.length - 1
                            ]?.phone;
                          const [countryCode, number] =
                            phoneNumber?.trim().length > 0
                              ? phoneNumber?.split(
                                  phoneNumber.includes("-") ? "-" : " "
                                )
                              : ["", ""];
                          const formattedCountryCode = countryCode.startsWith(
                            "+"
                          )
                            ? countryCode
                            : `+${countryCode ? countryCode : "44"}`;

                          setBusinessMobile(number);
                          setBusinessMobileCode(formattedCountryCode);
                          setBusinessWebsite(
                            userInfo?.businesses[
                              userInfo?.businesses?.length - 1
                            ]?.webSiteURL
                          );
                          setBrandImage(
                            userInfo?.businesses[
                              userInfo?.businesses?.length - 1
                            ]?.imageUri
                              ? process.env.REACT_APP_API_URL +
                                  userInfo?.businesses[
                                    userInfo?.businesses?.length - 1
                                  ]?.imageUri
                              : ""
                          );
                        }
                      }}
                      onSaveChangesBtnClicked={() => {
                        if (
                          name?.trim() !== "" &&
                          email?.trim() !== "" &&
                          businessName?.trim() !== "" &&
                          brandImage !== ""
                        ) {
                          userInfo?.kycStatus !== "Verified"
                            ? updateUserProfileRequest()
                            : setIsConfirmAccountModalVisible("profileUpdate");
                        } else {
                          setNameError(
                            name?.trim() === "" ? "Name is required" : ""
                          );
                          setEmailError(
                            email?.trim() === "" ? "Email is required" : ""
                          );
                          setBusinessNameError(
                            businessName?.trim() === ""
                              ? "Business name is required"
                              : ""
                          );
                          setBusinessProfileImageError(
                            brandImage === ""
                              ? "Business profile image is required"
                              : ""
                          );
                        }
                      }}
                      isAccountVerified={
                        userInfo?.kycStatus === "Verified" ? true : false
                      }
                      brandImage={brandImage}
                      onBrandImageChange={(value) => {
                        setBusinessProfileImageError("");
                        if (value) {
                          setToCropImage(value);
                          setIsCropModalVisible(true);
                        } else {
                          setBrandImage(value);
                        }
                      }}
                    />
                  </div>
                )}

                {selectedSegmentIndexHelp === 2 && (
                  <PaymentDetails
                    taxValue={taxValue}
                    currentThreshold={thresholdValue}
                    currentBalance={balanceObject}
                    onPayNowBtnClicked={(item, index) => {
                      if (index === null) 
                      {
                        dispatch({
                          type: BANNER_CONTENT,
                          payload: {
                            text: "Update your billing payment card to avoid sales interruptions.",
                            headingText: "Update your billing payment card",
                            showBanner: true,
                            type: "Error",
                            withClose: true,
                            withIcon: true,
                            position: "top",
                            width: 391,
                          },
                        });
                      }
                      else
                      {
                      setIsCentralModalVisible("payNow");
                      setSelectedPayNowIndex(index);
                      }
                    }}
                    onEditThresholdBtnClicked={(item, index) => {
                      setIsCentralModalVisible("threshold");
                      setSelectedThresholdIndex(index);
                      setSelectedThresholdAmount(item?.amount);
                      setSelectedThresholdMinValue(
                        item?.currency?.minimumThreshold
                      );
                      setSelectedThresholdMaxValue(
                        item?.currency?.maximumThreshold
                      );
                    }}
                    onAddPaymentMethodBtnClicked={() =>
                      setIsCentralModalVisible("addPaymentMethod")
                    }
                    onViewInvoiceHistoryBtnClicked={() =>
                      setShowInvoiceHistory(true)
                    }
                    paymentData={cardList}
                    onPaymentOptionClicked={(index, rootIndex) => {
                      if (index === 0) {
                        setDefaultCardRequest(cardList[rootIndex]?.id);
                      } else {
                        setIsConfirmAccountModalVisible("removePaymentCard");
                        setSelectedPaymnetCardId(cardList[rootIndex]?.id);
                        // removeCardRequest(cardList[rootIndex]?.id);
                      }
                    }}
                  />
                )}

                {selectedSegmentIndexHelp === 3 && (
                  <div
                    style={{
                      marginBottom: mailchimpUserInfo?.isConnected
                        ? "60px"
                        : "160px",
                    }}
                  >
                    {mailchimpUserInfo?.isConnected ? (
                      <div class="allla SettingCommonBoxCont">
                        <div
                          className="generic-common-row-div-space-between-not-centered SettingPaymentTopCont"
                          style={{
                            paddingBottom: 30,
                            borderBottomWidth: 1,
                          }}
                        >
                          <div className="CommonSpaceBetweenRow">
                            <div className="CommonSpaceBetweenCol">
                              <div>
                                <Text textclass="Heading-div" variant={"m500"}>
                                  Mailchimp
                                </Text>
                              </div>
                              <div
                                className="generic-common-row-div"
                                style={{ marginTop: 16 }}
                              >
                                <SuccessFilledWhite width={24} height={24} />
                                <Text
                                  color={"var(--green)"}
                                  marginLeft={4}
                                  variant={"m300"}
                                >
                                  Connected
                                </Text>
                              </div>
                            </div>

                            <div className="CommonSpaceBetweenCol">
                              <div className="BtnCont296">
                                <ButtonPrimary
                                  type={"error"}
                                  isSecondary={true}
                                  label={"Disconnect Mailchimp account"}
                                  onPress={() =>
                                    setIsCentralModalVisible(
                                      "disconnectMailChimp"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="SettingPaymentBodyCont CommonSpaceBetweenRow Gap34"
                          style={{
                            marginTop: 30,
                          }}
                        >
                          <div className="generic-common-column-div-half-width">
                            <Text variant={"m400"}>Username</Text>

                            <div className="email-textWrap">
                              <Text
                                marginTop={8}
                                color={"var(--midBlue)"}
                                variant={"m400"}
                              >
                                {mailchimpUserInfo?.mailChimpUserName}
                              </Text>
                            </div>
                          </div>
                          <div className="generic-common-column-div-half-width">
                            <Text variant={"m400"}>Email</Text>

                            <div className="email-textWrap">
                              <Text
                                marginTop={8}
                                color={"var(--midBlue)"}
                                variant={"m400"}
                              >
                                {mailchimpUserInfo?.mailChimpUserEmail}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div class="allla SettingCommonBoxCont">
                        <div className="SettingPaymentTopCont">
                          <div className="CommonSpaceBetweenRow">
                            <div className="CommonSpaceBetweenCol">
                              <div>
                                <Text textclass="Heading-div" variant={"m500"}>
                                  Mailchimp
                                </Text>
                              </div>
                              <div style={{ marginTop: 16, width: "70%" }}>
                                <Text variant={"r300"}>
                                Connect your Mailchimp account to automate list creation at the checkout’s email marketing opt in. Manage your list directly in Mailchimp.
                                </Text>
                              </div>
                            </div>

                            <div className="CommonSpaceBetweenCol">
                              <div className="BtnCont296">
                                <ButtonPrimary
                                  label={"Connect to Mailchimp"}
                                  onPress={() => getMailChimpLoginURLRequest()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {selectedSegmentIndexHelp === 1 && (
                  <div>
                    {isStripeDetailsSubmitted === true ? (
                      <div style={{ paddingTop: 40 }}>
                        <div className="setting-tittle-container">
                          <Text textclass="Heading-div" variant={"m500"}>
                            Connected payment methods
                          </Text>
                        </div>

                        <div class="allla SettingCommonBoxCont">
                          <div
                            className="generic-common-row-div-space-between-not-centered SettingPaymentTopCont"
                            style={{
                              paddingBottom: 30,
                              borderBottomWidth: 1,
                            }}
                          >
                            <div className="CommonSpaceBetweenRow">
                              <div className="CommonSpaceBetweenCol">
                                <div>
                                  <Text
                                    textclass="Heading-div"
                                    variant={"m500"}
                                  >
                                    Online payment (Powered by Stripe)
                                  </Text>
                                </div>
                                <div
                                  className="generic-common-row-div"
                                  style={{ marginTop: 16 }}
                                >
                                  <SuccessFilledWhite width={24} height={24} />
                                  <Text
                                    color={"var(--green)"}
                                    marginLeft={4}
                                    variant={"m300"}
                                  >
                                    Connected
                                  </Text>
                                </div>
                              </div>

                              <div className="CommonSpaceBetweenCol">
                                <div className="BtnCont296">
                                  <ButtonPrimary
                                    isSecondary={true}
                                    label={"Remove payment method"}
                                    onPress={() =>
                                      setIsConfirmAccountModalVisible(
                                        "removePaymentMethod"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="SettingPaymentBodyCont CommonSpaceBetweenRow Gap34"
                            style={{
                              marginTop: 30,
                            }}
                          >
                            <div className="generic-common-column-div-half-width">
                              <Text variant={"m400"}>Account</Text>

                              <Text
                                marginTop={8}
                                color={"var(--midBlue)"}
                                variant={"m400"}
                              >
                                {userInfo?.stripeAccountId}
                              </Text>
                            </div>
                            <div className="generic-common-column-div-half-width">
                              <Text variant={"m400"}>
                                Default payment method
                              </Text>

                              <Text
                                marginTop={8}
                                color={"var(--midBlue)"}
                                variant={"m400"}
                              >
                                {"Yes"}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div class="allla SettingCommonBoxCont">
                        <div
                          className="SettingPaymentTopCont"
                          style={{
                            paddingBottom: 30,
                            borderBottomWidth: 1,
                          }}
                        >
                          <div className="CommonSpaceBetweenRow">
                            <div className="CommonSpaceBetweenCol">
                              <div>
                                <Text textclass="Heading-div" variant={"m500"}>
                                  Online payment (Powered by Stripe)
                                </Text>
                              </div>
                              <div style={{ marginTop: 16 }}>
                                <Text variant={"r300"}>
                                  You can accept payments directly to your
                                  Stripe account by connecting your own payment
                                  gateway.
                                </Text>
                              </div>
                            </div>

                            <div className="CommonSpaceBetweenCol">
                              <div className="BtnCont296">
                                <ButtonPrimary
                                  label={
                                    isStripeDetailsSubmitted === "no"
                                      ? "Enable online payments"
                                      : "Resume online payments"
                                  }
                                  onPress={() => stripeConnect()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="SettingPaymentBodyCont CommonSpaceBetweenRow Gap34"
                          style={{
                            marginTop: 30,
                          }}
                        >
                          <div
                            style={{ backgroundColor: "var(--veryLightGrey)" }}
                            className="CommonSpaceBetweenColumn payment-connect-not-added-card-container"
                          >
                            {" "}
                            <Text textclass="Heading-div" variant={"m400"}>
                              Accepts all major credit cards
                            </Text>
                            <div
                              className="PaymentSystemIconCont"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <img
                                style={{ height: 64, width: 92 }}
                                src="/Visa.png"
                                alt=""
                              />
                              <img
                                style={{ height: 64, width: 92 }}
                                src="/MasterCard.png"
                                alt=""
                              />
                              <img
                                style={{ height: 64, width: 92 }}
                                src="/Amex.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div
                            style={{ backgroundColor: "var(--veryLightGrey)" }}
                            className="CommonSpaceBetweenColumn payment-connect-not-added-card-container"
                          >
                            {" "}
                            <Text textclass="Heading-div" variant={"m400"}>
                              Convenient mobile checkout on the web
                            </Text>
                            <div
                              className="PaymentSystemIconCont"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <img
                                style={{ height: 64, width: 92 }}
                                src="/ApplePay.png"
                                alt=""
                              />
                              <img
                                style={{ height: 64, width: 92 }}
                                src="/GooglePay.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="SettingPaymentFooterCont"
                          style={{ marginTop: 40 }}
                        >
                          <div className="BtnCont360">
                            <ButtonPrimary
                              isSecondary={true}
                              label={"Learn more"}
                              onPress={() => {}}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default Settings;
